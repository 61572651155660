import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import CustomButton from "../../components/form/CustomButton";
import { aceptarDeclinarSolicitud, getModules, getNiveles } from "../../helpers/configRoutes/backend_helper";
import { useNavigate, useParams } from "react-router-dom";

const AceptarModal = ({ modal, setModal, validation }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading,setLoading] = useState(false)
    const [niveles, setNiveles] = useState([]);
    const [nivelSeleccionado, setNivelSeleccionado] = useState(null);
    // param:{model:'SolicitudTrabajo'}
    useEffect(() => {
        const getNivelesData = async () => {
            const response = await getNiveles('get', {});
            if (response.status) {
                setNiveles(response.niveles);
            }
        }
        getNivelesData();
    }, []);

    const handleNivelChange = (nivel) => {
        setNivelSeleccionado(nivel);
    };

    const save = async() => {
        setLoading(true)
        const response = await aceptarDeclinarSolicitud({ action: 1, nivel_id: nivelSeleccionado, enfermero_id:id})
        if(response.status){
            navigate(`/enfermero/${id}`)
        }
        setLoading(false)
    }

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal} classModal={'lg:w-[24%]'}>

                <div className="p-4">
                    <h2 className="text-center text-[22px] font-semibold">Selecciona el nivel del enfermero</h2>
                </div>
                {/* Lista de niveles */}
                <div className="p-4">
                    {niveles.map((nivel, index) => (
                        <label
                            key={index}
                            className="flex items-center my-2 cursor-pointer group"
                        >
                            <input
                                type="radio"
                                name="nivel"
                                value={nivel.id}
                                checked={nivelSeleccionado === nivel.id}
                                onChange={() => handleNivelChange(nivel.id)}
                                className="hidden"
                            />
                            <div
                                className={`relative w-6 h-6 border-4 rounded-full flex items-center justify-center transition-all duration-300 ${nivelSeleccionado === nivel.id
                                    ? 'border-blue-500' // Cambia aquí el color del borde cuando está seleccionado
                                    : 'border-gray-400' // Cambia aquí el color cuando no está seleccionado
                                    }`}
                            >
                                {nivelSeleccionado === nivel.id && (
                                    <div className="w-3.5 h-3.5 bg-blue-500 rounded-full transition-all duration-300"></div>
                                )}
                            </div>
                            <span className="ml-3 text-gray-700 group-hover:text-blue-500 transition-colors duration-300">
                                {nivel.nombre}
                            </span>
                        </label>
                    ))}
                </div>


                {/* Pie del modal */}

                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                    <CustomButton
                        onClick={() => setModal({ ...modal, show: false })}
                        type={"button"}
                        className="w-full lg:w-[30%] bg-o2o-gray-title"
                    >
                        Regresar
                    </CustomButton>
                    <CustomButton
                        isDisabled={!nivelSeleccionado}
                        loading={loading}
                        textLoad={'Procesando'}
                        type={"success"}
                        onClick={save}
                        className="w-full lg:w-[30%] bg-o2o-black"
                    >
                        Continuar
                    </CustomButton>
                </div>
            </BaseModalAnimated>

        </>
    )
}

export default AceptarModal;