import React from "react";

const DireccionData = ({item}) => {
    return (
        <>
            <div className="flex flex-col">
                <div className='flex flex-row items-center w-full space-x-2'>
                    <p className='font-bold text-xs text-nowrap'>Dirección</p>
                    <hr className='w-full px-2' />
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.calle}</p>
                        <p className='text-sm'>Calle</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.numeroExterior}</p>
                        <p className='text-sm'>Número exterior</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.numeroInterior}</p>
                        <p className='text-sm'>Número interior</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.colonia}</p>
                        <p className='text-sm'>Colonia</p>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.cp}</p>
                        <p className='text-sm'>Código postal</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.municipio_nombre}</p>
                        <p className='text-sm'>Municipio</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.estado_nombre}</p>
                        <p className='text-sm'>Estado</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.pais_nombre}</p>
                        <p className='text-sm'>País</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DireccionData;