import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import CustomIcon from "../../components/main/CustomIcon";
import { enfermeroResource } from "../../helpers/configRoutes/backend_helper";
import AceptarModal from "./AceptarModal";
import DeclinarModal from "./DeclinarModal";
import DireccionData from "../../components/CommonEnfermeroAdministrador/DireccionData";
import GeneralInfo from "../../components/CommonEnfermeroAdministrador/GeneralInfo";

const AplicationInfo = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const [openModal, setOpenModal] = useState({ acceptAccount: false });
    const [openDeclinarModal, setOpenDeclinarModal] = useState({ declainRequest: false });

    useEffect(() => {
        const getItem = async () => {
            try {
                let response = { status: false };
                response = await enfermeroResource('show', {}, id);
                setItem(response.enfermero);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        if (id) {
            getItem();
        }
    }, [id]);



    return (
        <>
            <div className="flex flex-col w-full bg-white">
                <div className='flex flex-col p-4'>
                    <div className='flex flex-row items-center '>
                        <button onClick={() => navigate("/registros-pendientes")} className='inline-flex hover:underline'>
                            <CustomIcon icon="arrow_back_ios" />
                            <p className='font-bold text-lg'>Ingresos pendientes</p>
                        </button>
                    </div>
                    <div className='flex flex-col space-y-8 mt-5'>
                        <GeneralInfo item={item}  >
                            <div className='flex flex-col flex-1 space-y-1 mb-3'>
                                <button className='bg-black hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenModal({ ...openModal, show: true })}>
                                    Aceptar solicitud
                                </button>
                                <button className='bg-[#696969] hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenDeclinarModal({ ...openDeclinarModal, show: true })}>
                                    Declinar solicitud
                                </button>
                                <button className='bg-[#8FA147] hover:opacity-90 text-white px-1 rounded' onClick={() => setOpenModal({ ...openModal, declainRequest: true })}>
                                    Agregar notas
                                </button>
                            </div>
                        </GeneralInfo>

                        <DireccionData item={item} />
                        <div className="flex flex-col">
                            <div className='flex flex-row items-center w-full space-x-2'>
                                <p className='font-bold text-xs text-nowrap'>Formación profesional</p>
                                <hr className='w-full px-2' />
                            </div>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.nivel_nombre} en Enfermería</p>
                                    <p className='text-sm'>Estudios</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.acreditaciones?.casa_estudios}</p>
                                    <p className='text-sm'>Casa de estudios</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.acreditaciones?.cedula_profesional}</p>
                                    <p className='text-sm'>cédula profesional</p>
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{item?.acreditaciones?.graduacion_fecha}</p>
                                    <p className='text-sm'>Periodo</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className='flex flex-row items-center w-full space-x-2'>
                                <p className='font-bold text-xs text-nowrap'>Experiencia laboral</p>
                                <hr className='w-full px-2' />
                            </div>
                            {item?.acreditaciones?.experiencias_laborales?.map((experiencia, index) => (
                                <div key={index} className='flex flex-row justify-between'>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{experiencia.fecha_inicio} - {experiencia.fecha_fin}</p>
                                        <p className='text-sm'>Periodo</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'></p>
                                        <p className='text-sm'>institución</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{experiencia.cargo}</p>
                                        <p className='text-sm'>Cargo</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{experiencia.actividades}</p>
                                        <p className='text-sm'>Actividades</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex flex-col">
                            <div className='flex flex-row items-center w-full space-x-2'>
                                <p className='font-bold text-xs text-nowrap'>Formación académica</p>
                                <hr className='w-full px-2' />
                            </div>
                            {item?.acreditaciones?.formacion_academica?.map((formacion, index) => (
                                <div key={index} className='flex flex-row justify-between'>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{formacion.fecha_inicio} - {formacion.fecha_fin}</p>
                                        <p className='text-sm'>Periodo</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{formacion.institucion}</p>
                                        <p className='text-sm'>Institución</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{formacion.estudios}</p>
                                        <p className='text-sm'>Estudios</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex flex-col">
                            <div className='flex flex-row items-center w-full space-x-2'>
                                <p className='font-bold text-xs text-nowrap'>Disponibilidad</p>
                                <hr className='w-full px-2' />
                            </div>
                            {item?.turnos?.map((turnoData, index) => (
                                <div key={index} className='flex flex-row justify-between'>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>
                                            {turnoData.dias.map(dia => (
                                                <span key={dia}>{dia} </span> // Puedes convertir el número del día a nombre si es necesario
                                            ))}
                                        </p>
                                        <p className='text-sm'>Días</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{turnoData.turno.nombre}</p>
                                        <p className='text-sm'>Turno</p>
                                    </div>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{turnoData.tiempo.nombre}</p>
                                        <p className='text-sm'>Duración</p>
                                    </div>
                                </div>
                            ))}
                        </div>



                    </div>
                </div>
                {/* <LevelsModal isOpen={openModal.acceptAccount} close={() => setOpenModal({ ...openModal, acceptAccount: false })} /> */}
                {/* <MessageDeclainRequestModal isOpen={openModal.declainRequest} close={() => setOpenModal({ ...openModal, declainRequest: false })} /> */}
            </div>
            {openModal.show &&
                <AceptarModal modal={openModal} setModal={setOpenModal} />
            }
            {openDeclinarModal.show &&
                <DeclinarModal modal={openDeclinarModal} setModal={setOpenDeclinarModal} />
            }
        </>
    );
};

export default AplicationInfo;
