import React, { useState } from 'react';
import CustomInput from '../../components/form/CustomInput';
import { Link, useNavigate } from 'react-router-dom';
import CustomButtonConfirm from '../../components/form/CustomButtonConfirm';
import { loginSuccess, loginUser } from '../../store/auth/login/action';
import Modal from '../../components/modal/BaseModalAnimated';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { postLoginToken, resetPassword,/*Funcion parar reset*/ } from '../../helpers/configRoutes/backend_helper'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false);

    const validation = useFormik({
        initialValues: {
            email: '',
            reset: true,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Correo electrónico no válido").required("Ingresa tu correo electrónico"),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await resetPassword(values)
            if (response.status == true) {
                navigate('/login')
            } else {
                if (response.errors?.length) {
                    setErrors(response.errors);
                }
            }
            setLoading(false)
        }
    });

    return (
        <>
            <div className="flex flex-row justify-center items-center w-screen h-screen select-none">
                <div className="hidden w-1/2 md:flex">
                    <p className="text-center mx-auto">Imagen</p>
                </div>
                <div className="flex flex-col justify-between max-w-screen-sm w-full md:w-1/2 h-full p-4 md:p-0 md:h-3/4">
                    <div className="flex flex-col font-bold text-5xl text-o2o-gray-title h-1/2">
                        <p>One2One</p>
                        <p>Nurses</p>
                    </div>

                    <div className="flex flex-col items-center text-center mb-6" style={{ width: '255px', height: '105px' }}>
                        <h3 className="text-xl font-semibold mb-2">¿Olvidaste tu contraseña?</h3>
                        <p className="text-gray-600">Ingresa el correo electrónico asociado a tu cuenta DoctorNow.</p>
                    </div>

                    <form className="flex flex-col justify-end md:w-1/2 h-1/2" onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        <div className="flex w-full my-4">
                            <CustomInput
                                label="Correo electrónico"
                                type="email"
                                name="email"
                                labelClassName={'text-[18px]'}
                                error={validation.errors.email}
                                value={validation.values.email}
                                handleChange={validation.handleChange}
                            />
                        </div>

                        <div className="flex w-full my-4">
                            <CustomButtonConfirm
                                loading={loading}
                                textLoad={'Procesando'}
                                type="success"
                                className=""
                                text="Recuperar contraseña"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
