import React from "react";

const GeneralInfo = ({item,children}) => {
    return (
        <>
            <div className="flex flex-row w-full justify-between " >
                <div className='flex w-1/6'>
                    <img src='https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg'
                        alt="nurce img"
                        className='object-fill h-24 rounded-full mx-auto'
                    />
                </div>
                <div className='flex flex-col w-5/6'>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.nombre}</p>
                            <p className='text-sm'>Nombre</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.apellidos}</p>
                            <p className='text-sm'>Apellidos</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.movil}</p>
                            <p className='text-sm'>Móvil</p>
                        </div>
                        {children}
                    </div>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.curp}</p>
                            <p className='text-sm'>CURP</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.estadoCivil}</p>
                            <p className='text-sm'>Estado civil</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.edad}</p>
                            <p className='text-sm'>Edad</p>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <p className='font-medium text-lg'>{item?.email}</p>
                            <p className='text-sm'>Mail</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GeneralInfo;