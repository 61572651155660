import React, { useEffect, useState } from "react";
import { enfermeroResource, getAuthUser } from "../../helpers/configRoutes/backend_helper";
import FirstTab from "../../components/dashboard/nurces/tab/FirstTab";
import CustomTabs from "../../components/dashboard/nurces/tab/CustomTabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EstudiosAcreditaciones from "../enfermeros/EstudiosAcreditaciones";

const Persona = () => {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const title = location.pathname.startsWith('/enfermero') ? "Enfermero" : "Cliente";

    useEffect(() => {
        const getItem = async () => {
            try {
                let response = { status: false };

                if (location.pathname.startsWith('/enfermero')) {
                    response = await enfermeroResource('show', {}, id);
                    setItem(response.enfermero);
                } else if (location.pathname.startsWith('/cliente')) {
                    // response = await clientesResource('show', {}, id);
                    // setItem(response.cliente);
                }

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        if (id) {
            getItem();
        }
    }, [id, location.pathname]);

    const authUser = getAuthUser();
    const tabs = [
        { id: 1, label: 'Información general', content: <FirstTab enfermeroData={item} /> },
        { id: 2, label: 'Estudios y acreditaciones', content: <EstudiosAcreditaciones item={item}/> },
        { id: 3, label: 'Trabajo en One2One', content: '' },

    ];
    const tabsCliente = [
        { id: 1, label: 'Información general', content: '' },
        { id: 2, label: 'Pacientes registrados', content: '' },
        { id: 3, label: 'Historial de servicios', content: '' },
    ];
    const tabsInstituciones = [

    ];

    

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => navigate('/enfermeros')} 
                        className="text-blue-500 font-semibold">
                        &lt;
                    </button>
                    <h1 className="font-semibold text-lg">{title} </h1>
                </div>
                <div className="flex flex-col space-y-2">
                    <button className="bg-black text-white font-semibold py-2 px-4 rounded">Editar cuenta</button>
                    <button className="bg-gray-400 text-white font-semibold py-2 px-4 rounded">Bloquear cuenta</button>
                </div>
            </div>
            <CustomTabs tabs={tabs} />
        </>

    )
}

export default Persona;