import React, { useEffect, useState } from "react";
import FiltersPagination from "../../components/elements/FiltersPagination";
import { clientsResource, getDataHome, paymentsResource, plansResource } from "../../helpers/configRoutes/backend_helper";
import ColumnsSubscriptions from "./ColumnsSubscriptions";
import DataTableElement from "../../components/elements/DataTableElement";
import DatePicker from "react-datepicker";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"; // Import Recharts components
import { currencyUSD, currencyDecimal } from "../../helpers/forms/formater";

const Home = () => {
    
    

    return (
        <>
            
        </>
    );
}

export default Home;
