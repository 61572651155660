import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { enfermeroResource } from "../../helpers/configRoutes/backend_helper";
import GenericCard from "../../components/cards/GenericCard";
import CustomSelect from "../../components/form/CustomSelect";
import CustomButton from "../../components/form/CustomButton";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import CustomIcon from "../../components/main/CustomIcon";
import PreviewPDF from "../../components/modal/PreviewPdf";


const PendingRequest = () => {
    const navigate = useNavigate();
    const [enfermeros, setEnfermeros] = useState([]);
    const [filters, setFilters] = useState({ id: 1, search: '', sort: 'A-Z' });
    const sortList = [
        { id: 1, name: 'A-Z', value: 'asc' },
        { id: 2, name: 'Z-A', value: 'desc' },
    ]
    const [openFilterModal, setFilterModal] = useState(false);
    const handleChange = ({ name, value }) => {
        setFilters({ ...filters, [name]: value });
    }
    const [totalEnfermeros, setTotalEnfermeros] = useState(0);
    const [totalRegistrosN, setTotalRegistrosN] = useState(0);

    useEffect(() => {
        const getEnfermero = async () => {
            try {
                const response = await enfermeroResource('get', { params: { estatus_solicitud: true } });
                const enfermerosData = Array.isArray(response.enfermeros) ? response.enfermeros : [];

                setEnfermeros(enfermerosData);
                setTotalEnfermeros(enfermerosData.length);
                const count = enfermerosData.filter(enfermero => enfermero.solicitud?.estatus_id === 4).length;
                setTotalRegistrosN(count);
            } catch (error) {
                console.error("Error fetching enfermeros:", error);
            }
        };

        getEnfermero();
    }, []);
    const [load, setLoad] = useState(false)
    const [modalPdf, setModalPdf] = useState({ show: false, url: '' });
    const getDetalleUsuario = async (id) => {
        setLoad(true)
        const response = await enfermeroResource('show', { params: { report: 'pdf' } }, id)
        setLoad(false)
        console.log(response.pdf_base64)
        setModalPdf({ show: true, url: response.pdf_base64 })
    }

    return (
        <>
            <div className="flex flex-col w-full bg-white">
                <div className="flex items-center space-x-2">

                    <button onClick={() => navigate("/enfermeros")} className='inline-flex hover:underline'>
                        <CustomIcon icon="arrow_back_ios" />
                        <p className='font-bold text-lg'>Enfermeros</p>
                    </button>

                </div>
                <div className="flex flex-row w-1/2">
                    <div className="w-1/3 mx-2">
                        <GenericCard name="Nuevos registros" text="218" />
                    </div>
                    <div className="w-1/3 mx-2">
                        <GenericCard name="Registros pendientes" text={totalRegistrosN.toString()} />
                    </div>

                </div>
                <div className="flex flex-col w-full rounded shadow-xl border mt-4">
                    <div className="flex flex-row justify-between p-4">
                        <div className="flex flex-row w-3/4 items-center space-x-2">
                            <p className="">Ordenar por</p>
                            <div className="w-40">
                                <CustomSelect name="sort" value={filters.sort} options={sortList} handleChange={() => console.log('error')} />
                            </div>
                            <div className="w-40">
                                <CustomSelect name="sort" value={filters.sort} options={sortList} handleChange={() => console.log('error')} />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <CustomSearchInput
                                value={filters.search}
                                handleChange={({ target }) => handleChange(target)} />
                        </div>
                    </div>

                </div>

                <hr />
                <div className='flex flex-row w-full flex-wrap p-4'>
                    {enfermeros.length > 0 ? (
                        enfermeros.map((item, index) => (
                            <div className="flex w-1/5 items-center select-none" key={index}>
                                <div className="flex flex-col w-full bg-gray-200 m-2 p-2 rounded relative">
                                    {/* Etiqueta de estatus */}
                                    <div
                                        className={` top-2 mb-4 right-2 px-2 py-1 text-xs font-bold rounded ${item?.solicitud?.estatus_id === 'aprobado'
                                            ? 'bg-green-500 text-white'
                                            : item?.solicitud?.estatus_id === 4
                                                ? 'bg-yellow-500 text-black'
                                                : 'bg-red-500 text-white'
                                            }`}
                                    >
                                        {item?.solicitud?.estatus_id == 3 ? 'Con observaciones' : 'Revisión'}
                                    </div>

                                    <div className="flex flex-row w-full">
                                        <div className="flex w-1/3">
                                            <img
                                                src="https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg"
                                                alt="nurse img"
                                                className="object-fill h-12 rounded-full mx-auto"
                                            />
                                        </div>
                                        <div className="flex flex-col w-2/3">
                                            <p className="font-bold text-base">{item?.nombre}</p>
                                            <p className="text-xs">Nombre</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{item?.apellidos}</p>
                                        <p className="text-xs">Apellido</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base uppercase">
                                            {item?.curp ? item.curp : 'Sin información'}
                                        </p>
                                        <p className="text-xs">CURP</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base uppercase">{item?.nivel_nombre} en enfermería</p>
                                        <p className="text-xs">Estudios</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base uppercase">
                                            {item?.acreditaciones?.cedula_profesional}
                                        </p>
                                        <p className="text-xs">Cédula profesional</p>
                                    </div>
                                    <div className="flex flex-col w-full pt-2">
                                    <CustomButton
                                            onClick={() => navigate(`/registros-pendientes/${item?.id}`)}
                                            className="bg-black hover:bg-slate-600 mx-2 text-white text-center px-1 rounded"
                                        >
                                            Ver solicitud
                                        </CustomButton>
                                    </div>
                                    <div className="flex flex-col w-full pt-2">
                                        <CustomButton
                                            loading={load}
                                            textLoad={'Generando pdf'}
                                            onClick={() => getDetalleUsuario(item.id)}
                                            className="bg-o2o-primary hover:bg-o2o-primary-hover text-white text-center px-1 rounded"
                                        >
                                            Ver pdf
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>

                        ))
                    ) : (
                        <p className='text-center w-full'>No hay datos disponibles.</p>
                    )}
                </div>
                {modalPdf.show &&
                    <PreviewPDF modal={modalPdf} setModal={setModalPdf} />
                }
            </div>


        </>
    );
}

export default PendingRequest;
