import React, { useEffect, useState } from 'react';
import { enfermeroResource } from '../../../../helpers/configRoutes/backend_helper';
import DireccionData from '../../../CommonEnfermeroAdministrador/DireccionData';
import GeneralInfo from '../../../CommonEnfermeroAdministrador/GeneralInfo';

const FirstTab = ({ enfermeroData }) => {

    if (!enfermeroData) {
        return <p>Cargando datos...</p>;
    }

    return (
        <div className='tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md' id="enfermero-content-1">
            <GeneralInfo item={enfermeroData}>
                <div className='flex flex-col flex-1'>
                    <p className='font-medium text-lg'>
                        {new Date(Date.parse(enfermeroData?.created_at)).toLocaleDateString('es-MX', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        })}
                    </p>
                    <p className='text-sm'>Registrado desde</p>
                </div>
            </GeneralInfo>
            <div className='ml-10'>
                <DireccionData item={enfermeroData} />
                <div className="flex flex-row space-x-2 mt-10 mb-10">
                    <div className='flex flex-col w-1/2'>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Descripción personal</p>
                            <hr className='w-full px-2' />
                        </div>
                        <p className='font-normal text-lg'>{enfermeroData?.descripcionPersonal}</p>
                    </div>
                    <div className='flex flex-col w-1/2'>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Información que lo hace único</p>
                            <hr className='w-full px-2' />
                        </div>
                        <p className='font-normal text-lg'>{enfermeroData?.informacionUnica}</p>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row items-center w-full space-x-2">
                        <p className="font-bold text-xs whitespace-nowrap">Intereses personales</p>
                        <div className="flex-grow">
                            <hr className="border-t border-gray-300 mt-1" />
                        </div>
                    </div>
                    <div className="flex flex-row space-x-2">
                        {enfermeroData.interesesPersonales && enfermeroData.interesesPersonales.map((interes, index) => (
                            <span
                                key={index}
                                className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                            >
                                {interes.nombre}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstTab;
