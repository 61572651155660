import { useState, useEffect } from 'react';

const StarRating = ({ rating = 0, maxRating = 10, totalStars = 5 }) => {
  const [filledStars, setFilledStars] = useState(0);

  useEffect(() => {
    const percentage = (rating / maxRating) * totalStars;
    setFilledStars(percentage);
  }, [rating, maxRating, totalStars]);

  return (
    <div className="flex space-x-1">
      {[...Array(totalStars)].map((_, index) => (
        <Star key={index} fillPercentage={Math.min(1, Math.max(0, filledStars - index))} />
      ))}
    </div>
  );
};

const Star = ({ fillPercentage }) => {
  // Determina el ancho de relleno según el porcentaje (0 a 1)
  let fillWidth = 0;
  if (fillPercentage >= 0.75) fillWidth = 100;
  else if (fillPercentage >= 0.5) fillWidth = 75;
  else if (fillPercentage >= 0.25) fillWidth = 50;
  else if (fillPercentage > 0) fillWidth = 25;

  return (
    <div className="relative">
      {/* Estrella vacía */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-gray-300"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
      </svg>

      {/* Estrella rellena parcialmente */}
      {fillWidth > 0 && (
        <div className="absolute top-0 left-0 w-6 h-6 overflow-hidden" style={{ width: `${fillWidth}%` }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 text-yellow-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default StarRating;
