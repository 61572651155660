import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CustomTabs = ({ defaultTab, tabs }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(defaultTab || 1);

    const handleClick = (tab) => {
        if(tab.action){
            navigate(tab.action);
        } else {
            setActiveTab(tab.id);
        }
    };

    return (
        <div className="border-b border-gray-200">
            <div className="flex border-b-2 border-black max-w-full ml-0">
                {tabs.map((tab) => (
                    <button
                        disabled={tab.disabled}
                        key={tab.id}
                        className={`${
                            activeTab === tab.id
                                ? 'bg-white border-black text-black' // Fondo blanco para la pestaña activa
                                : 'bg-o2o-gris-arena border-transparent text-gray-500 hover:text-gray-700'
                        } py-2 px-4 sm:px-6 block border-t-2 border-l-2 border-r-2 rounded-t-lg font-medium focus:outline-none mx-1 sm:mx-2`}
                        onClick={() => handleClick(tab)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            
            {tabs.map((tab) => (
                <div key={tab.id} className={`${activeTab === tab.id ? 'block' : 'hidden'}`}>
                    {tab.content}
                </div>
            ))}
        </div>
    );
};

export default CustomTabs;
