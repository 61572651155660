import axios from "axios"
import { del, get, post, postFile, put, postData } from "./api_helper"
import * as url from "./url_helper"
import { ToastHelper } from './alerts/alertHelper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
    return null
}

const getAuthUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user).user
    return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}


//export const sucursalesResource = (type,data={},id=null) => typeResource(url.SUCURSALES_RESOURCE,type,data,id)
export const postLoginToken = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    return axios
        .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
        .then(response => {
            if (response.status >= 200 || response.status <= 299) return response.data
            throw response.data
        })
        .catch(err => {
            var message = ''
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Lo sentimos el recurso solicitado no existe"
                        break
                    case 422:
                        message = err.response.data.message
                        break
                    case 500:
                        message = "Lo sentimos! algo ha salido mal, por favor contacta a nuestro equipo de soporte."
                        break
                    case 401:
                        message = "Credenciales inválidas"
                        break
                    default:
                        message = err.response?.data?.message || err.message
                        break
                }
                ToastHelper(message, 'error')
                return err.response.data
            } else {
                message = err.message
            }
            throw message
        })
}

const typeResource = (route, type, data = {}, id = null) => {
    switch (type) {
        case 'get':
            return get(route, data);
            break
        case 'show':
            return get(`${route}/${id}`, data);
            break
        case 'post':
            return post(`${route}`, data);
            break
        case 'put':
            return put(`${route}/${id}`, data);
            break
        case 'del':
            return del(`${route}/${id}`);
            break
        case 'postFile':
            return postFile(`${route}`, data);
            break
    }

}

//Auth
export const resetPassword = (data) => post(url.RESET_PASSWORD, data)
export const logOut = () => post(url.LOG_OUT)
export const resetConfirm = (data) => post(url.RESET_CONFIRM, data)
export const validaToken = (data) => post(url.VALIDA_TOKEN, data)

//catalogs
export const getPrefixes = () => get(url.GET_PREFIXES)
export const getModules = () => get(url.GET_MODULES)
export const getPeriods = () => get(url.GET_PAYMENTS_PERIODS)
export const getStatuses = (params) => get(url.GET_STATUSES, params)
export const getEspecialidades = () => get(url.GET_ESPECIALIDADES)
export const getTurnos = () => get(url.GET_TIEMPOS)
export const getHabilidades = () => get(url.GET_HABILIDADES)
export const getAreasEsp = () => get(url.GET_OFERTA_SERVICIOS)
//Home
export const getDataHome = (params) => get(url.GET_DATA_HOME, params)
//Users
export const usersResource = (type, data = {}, id = null) => typeResource(url.USERS_RESOURCE, type, data, id)
//Clients
export const clientsResource = (type, data = {}, id = null) => typeResource(url.CLIENTS_RESOURCE, type, data, id)
// Plans
export const plansResource = (type, data = {}, id = null) => typeResource(url.PLANS_RESOURCE, type, data, id)
export const plansActivate = (data) => put(url.PLANS_ACTIVATE, data)
//payments
export const paymentsResource = (type, data = {}, id = null) => typeResource(url.PAYMENTS_RESOURCE, type, data, id)
//enfermero
export const enfermeroResource = (type, data = {}, id = null) => typeResource(url.ENFERMERO_RESOURCE, type, data, id)
export const enfermeroFiltersCms = (data) => post(url.ENFERMERO_FILTERS_CMS, data)
//Solicitudes de trabajo acciones

export const aceptarDeclinarSolicitud = (data) => post(url.ACEPTAR_DECLINAR_SOLICITUD, data)
// niveles
export const getNiveles = (type, data = {}, id = null) => typeResource(url.GET_NIVELES, type, data, id)
// declinar
export const getDeclinar = (type, data = {}, id = null) => typeResource(url.GET_DECLINAR, type, data, id)

export {
    getLoggedInUser,
    isUserAuthenticated,
    getAuthUser
}
