import React from "react";
import CustomButton from "../form/CustomButton";

const ExperienciaLab = ({ item }) => {
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Experiencia laboral</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {item?.acreditaciones?.experiencias_laborales.map((e, i) => (
                    <div key={i} className="flex flex-row justify-between pl-6 mt-4">
                        <div className="flex flex-col flex-1">
                            <p className="font-medium text-base">
                                {e.fecha_inicio ? new Date(e.fecha_inicio).getFullYear() : 'Año no disponible'} -{' '}
                                {e.fecha_fin ? new Date(e.fecha_fin).getFullYear() : 'Año no disponible'}
                            </p>
                            <p className="text-xs">periodo</p>
                        </div>

                        <div className="flex flex-col flex-1">
                            <p className="font-medium text-base">{e.institucion}</p>
                            <p className="text-xs">institución</p>
                        </div>
                        <div className="flex flex-col flex-1">
                            <p className="font-medium text-base">{e.cargo}</p>
                            <p className="text-xs">cargo</p>
                        </div>
                        <div className="flex flex-col flex-1">
                            <p className="font-medium text-base">{e.actividades}</p>
                            <p className="text-xs">actividades</p>
                        </div>
                        <div className="flex flex-col flex-1">
                            {/* <CustomButton
                                className="w-full bg-o2o-black text-xs text-white"
                            >
                                Ver documento
                            </CustomButton> */}

                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ExperienciaLab;
