import React from "react";

const AreasEspecial = ({ item }) => {

    return (
        <>
            <div className="flex flex-row flex-wrap gap-2">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Áreas de la salud en las que se especializa</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {
                item?.acreditaciones?.servicios?.map((servicios, index) => (
                    <span
                        key={index}
                        className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                    >
                        {servicios.nombre}
                    </span>
                ))}
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Perfil de pacientes con mayor experiencia </p>
                    <hr className="flex-1 ml-2" />
                </div>
                {/* {
                item?.acreditaciones?.servicios?.map((servicios, index) => (
                    <span
                        key={index}
                        className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                    >
                        {servicios.nombre}
                    </span>
                ))} */}
            </div>
        </>
    );
}

export default AreasEspecial;