import React, { useState } from 'react';
import CustomInput from '../../components/form/CustomInput';
import { Link, useNavigate } from 'react-router-dom';
import CustomButtonConfirm from '../../components/form/CustomButtonConfirm';
import { loginSuccess, loginUser } from '../../store/auth/login/action';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { postLoginToken, resetPassword,/*Funcion parar reset*/ } from '../../helpers/configRoutes/backend_helper'

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false)

    const validation = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Correo electrónico no válido").required("Ingresa tu correo electrónico"),
            password: Yup.string().when('forgotPassword', {
                is: false,
                then: Yup.string().required("Ingresa tu contraseña")
            })
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            if (forgotPassword) {
                const response = await resetPassword(values)
                if (response.status == true) {
                    setForgotPassword(false)
                }
            } else {
                const response = await postLoginToken(values)
                if (response.status == true) {
                    dispatch(loginSuccess(response))
                    navigate('/')
                } else {
                    if (response.errors?.length) {
                        setErrors(response.errors);
                    }
                }
            }
            setLoading(false)
        }
    });

    return (

        <>

            <div className="flex flex-row justify-center items-center w-screen h-screen select-none">
                <div className="hidden w-1/2 md:flex">
                    <img src='/images/login.png' alt="Descripción de la imagen" className="mx-auto" />
                </div>
                <div className="flex flex-col justify-between max-w-screen-sm w-full md:w-1/2 h-full p-4 md:p-0 md:h-3/4">
                    <div className="flex flex-col font-bold text-5xl text-o2o-gray-title h-1/2">
                        <p>One2One</p>
                        <p>Nurses</p>
                    </div>
                    <form className="flex flex-col justify-end md:w-1/2 h-1/2" onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        <div className="flex w-full my-4">
                            <CustomInput
                                label="Email Address"
                                type="email"
                                name="email"
                                labelClassName={'text-[18px]'}
                                error={validation.errors.email}
                                value={validation.values.email}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <div className="flex w-full my-4">
                            <CustomInput
                                label="Password"
                                type={"password"}
                                name="password"
                                labelClassName={'text-[18px]'}
                                error={validation.errors.password}
                                value={validation.values.password}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <div className="flex w-full my-4">
                            <CustomButtonConfirm
                                loading={loading}
                                textLoad={'Processing'}
                                type="success"
                                className=""
                                text={forgotPassword ? "Reset password" : "Sign In"}
                            />
                        </div>
                        <div className="flex w-full my-4">
                            <Link
                                to="/forgotPassword"
                                className="hover:underline text-center mx-auto"
                            >
                                ¿Olvidaste tu contraseña?
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Login;
