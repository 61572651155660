import React from "react";

const FormacionProfesional = ({ item,validation,edit }) => {
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col flex-1 pl-6">
                        <p className="font-medium text-base">{item?.acreditaciones?.estudios_nombre}</p>
                        <p className="text-xs">Estudios</p>
                    </div>
                    <div className="flex flex-col flex-1 pl-6">
                        <p className="font-medium text-base">{item?.acreditaciones?.casa_estudios}</p>
                        <p className="text-xs">Casa de estudios</p>
                    </div>
                    <div className="flex flex-col flex-1 pl-6">
                        <p className="font-medium text-base">{item?.acreditaciones?.cedula_profesional}</p>
                        <p className="text-xs">Cédula profesional</p>
                    </div>
                    <div className="flex flex-col flex-1 pl-6">
                        <p className="font-medium text-base">{item?.acreditaciones?.graduacion_fecha}</p>
                        <p className="text-[12px]">Periodo</p>
                    </div>
                </div>
                <div className="flex flex-row items-center w-full pl-6 mt-[23px]">
                    <p className="font-bold text-xs whitespace-nowrap">Descripción profesional</p>
                    <hr className="flex-1 ml-2" />
                </div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col flex-1 pl-6">
                        <p className="text-sm">{item?.acreditaciones?.descripcion_profesional}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormacionProfesional;
