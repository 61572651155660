import React, { useEffect, useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import { Link } from "react-router-dom";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import CustomSelect from "../../components/form/CustomSelect";
import CustomButton from "../../components/form/CustomButton";
import DataTableElement from "../../components/elements/DataTableElement";
import { enfermeroResource } from "../../helpers/configRoutes/backend_helper";
import Columns from "./Columns";
import Filters from "../pendingRequest/Filters";
import PreviewPDF from "../../components/modal/PreviewPdf";

const Enfermeros = () => {
    const [enfermeros, setEnfermeros] = useState([]);
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading enfermero...' });
    const [totalEnfermeros, setTotalEnfermeros] = useState(0);
    const [totalRegistrosN, setTotalRegistrosN] = useState(0);
    const [openModal, setOpenModal] = useState({ acceptAccount: false });
    const [modalPdf, setModalPdf] = useState({ show: false,url:'' });

    const [filters, setFilters] = useState({ id: 1, search: '', sort: 'A-Z' });
    const [openFilterModal, setFilterModal] = useState(false);
    const handleChange = ({ name, value }) => {
        setFilters({ ...filters, [name]: value });
    }
    const sortList = [
        { id: 1, name: 'A-Z', value: 'asc' },
        { id: 2, name: 'Z-A', value: 'desc' },
    ]

    useEffect(() => {
        const getEnfermero = async () => {
            const response = await enfermeroResource('get', {});
            const enfermeros = Array.isArray(response.enfermeros) ? response.enfermeros : [];

            setEnfermeros(enfermeros);
            setTotalEnfermeros(enfermeros.length);
            const count = enfermeros.filter(enfermero => enfermero.solicitud?.estatus_id === 4).length;
            setTotalRegistrosN(count);
        };
        getEnfermero();
    }, []);
    const cols = Columns(setModalPdf);

    return (
        <>
            <div className="flex flex-col w-full p-4">
                <div className="flex flex-row">
                    <p className="font-bold text-lg">Enfermeros</p>
                </div>
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row w-1/2">
                        <div className="w-1/3">
                            <GenericCard name="Total de usuarios" text={totalEnfermeros.toString()} />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Usuarios activos" text="218" />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Nuevos registros" text={totalRegistrosN.toString()} />
                        </div>
                    </div>
                    <div className="flex flex-col justify-between w-1/2">
                        <div className="flex flex-row w-full h-10 space-x-2">
                            <Link to='/registros-pendientes' className={`flex justify-center w-1/2 items-center rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary`}>
                                Registros pendientes
                            </Link>
                            <button className={`w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary`}>
                                Cuentas bloqueadas
                            </button>
                        </div>
                        <div className="flex flex-row">
                            <CustomSearchInput
                                value={filters.search}
                                handleChange={({ target }) => handleChange(target)} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full rounded shadow-xl border mt-4">
                    <div className="flex flex-row justify-between p-4">
                        <div className="flex flex-row w-3/4 items-center space-x-2">
                            <p className="">Ordenar por</p>
                            <div className="w-40">
                                <CustomSelect name="sort" value={filters.sort} options={sortList} handleChange={() => console.log('error')} />
                            </div>
                            <div className="w-40">
                                <CustomSelect name="sort" value={filters.sort} options={sortList} handleChange={() => console.log('error')} />
                            </div>
                            <div className="w-40">
                                <CustomSelect name="sort" value={filters.sort} options={sortList} handleChange={() => console.log('error')} />
                            </div>
                        </div>
                        <div className="flex flex-row w-1/4 items-center space-x-2">
                            <p>Filtros</p>
                            <CustomButton onClick={() => setOpenModal({ ...openModal, show: true })} className="tu-clase-adicional">
                                Personaliza tu búsqueda
                            </CustomButton>

                        </div>
                    </div>

                </div>
                <DataTableElement columns={cols} tableLoad={tableLoad} items={enfermeros} />
            </div>
            {openModal.show &&
                <Filters modal={openModal} setModal={setOpenModal} items={enfermeros} setItems={setEnfermeros}/>
            }
            {modalPdf.show &&
                <PreviewPDF modal={modalPdf} setModal={setModalPdf}/>
            }
            
        </>
    );
}

export default Enfermeros;