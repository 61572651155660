import React, { useState } from "react";
import FormacionProfesional from "../../components/CommonEnfermeroAdministrador/FormacionProfesional";
import ExperienciaLab from "../../components/CommonEnfermeroAdministrador/ExperienciaLab";
import Estudios from "../../components/CommonEnfermeroAdministrador/Estudios";
import InteresesPersonales from "../../components/CommonEnfermeroAdministrador/HabilidadesProfesionales";
import HabilidadesProfesionales from "../../components/CommonEnfermeroAdministrador/HabilidadesProfesionales";
import AreasEspecial from "../../components/CommonEnfermeroAdministrador/AreasEspecial";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validaDates } from "../../helpers/formularios/Validation";

const EstudiosAcreditaciones = ({ item,edit }) => {
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item,
        validationSchema: Yup.object({
            estudios: Yup.string().required('Campo requerido'),
            casa_estudios: Yup.string().required('Campo requerido'),
            cedula_profesional: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{6,8}$/, 'La cedula debe tener entre 8 y 10 dígitos'),
            graduacion_fecha: validaDates(0, 'Tu graduacion tiene que ser anterior a la fecha de hoy '),
            descripcion_profesional: Yup.string().required('Campo requerido'),
            experiencias_laborales: Yup.array().of(
                Yup.object().shape({
                    cargo: Yup.string().required('Campo requerido'),
                    actividades: Yup.string().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),

                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_academica: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_especialidades: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ),
            servicios: Yup.array().min(1)
        }),
        onSubmit: async (values, { setErrors }) => {
           
        },
    });
    return (
        <>
            <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
                <div>
                    <FormacionProfesional item={item} validation={validation} edit={edit}/>
                </div>
                <div>
                    <ExperienciaLab item={item} />
                </div>
                <div >
                    <Estudios item={item} />
                </div>
                <div >
                    <HabilidadesProfesionales item={item} />
                </div>
                <div>
                    <AreasEspecial item={item}/>
                </div>
            </div>
        </>
    );
};

export default EstudiosAcreditaciones;
